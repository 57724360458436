@if (group) {
  <div>
    <h3>{{ 'Group creator' | translate }}:</h3>
    <div>
      <span>{{ group.creator.fullname }}</span>
    </div>
    <h3>{{ 'Members & Owners of group' | translate }}: {{ group.name }}</h3>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'Members' | translate }} </mat-panel-title>
        <mat-panel-description> {{ 'Click to expand/collapse' | translate }} </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list>
        @for (member of group.members; track member) {
          <mat-list-item>{{ member.fullname }}</mat-list-item>
        }
      </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'Owners' | translate }} </mat-panel-title>
        <mat-panel-description> {{ 'Click to expand/collapse' | translate }} </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list>
        @for (owner of group.owners; track owner) {
          <mat-list-item>{{ owner.fullname }}</mat-list-item>
        }
      </mat-list>
    </mat-expansion-panel>
  </div>
}
