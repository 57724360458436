<div>
    <mat-form-field>
      <mat-label>{{ ' Add technique (e.g. Laserscan or Modelling)' | translate }}</mat-label>
      <input
        matInput
        type="text"
        name="technique"
        [formControl]="techniqueControl"
        />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'Add program' | translate }}</mat-label>
      <input
        matInput
        type="text"
        name="program"
        [formControl]="softwareControl"
        />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'Add equipment' | translate }}</mat-label>
      <input
        matInput
        type="text"
        name="equipment"
        [formControl]="equipmentControl"
        />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'Add creation date (format: mm/dd/yyyy)' | translate }}</mat-label>
    <input
      matInput
      name="date"
      [matDatepicker]="picker"
      [formControl]="dateControl"
      [min]="minDate"
      />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
     <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  </div>
  <div class="meta-button">
    <button
      mat-flat-button
      color="primary"
      [disabled]="!isFormValid"
      (click)="addNewCreationData()"
      >
      Add
    </button>
  </div>
  
  <mat-divider></mat-divider>

  <app-optional-card-list [optionalData]="entity.creation" [propertyType]="'creation'"></app-optional-card-list>
