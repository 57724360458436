@if (entity$ | async; as entity) {

    @if (digitalEntity$ | async; as digitalEntity) {
      <p [ngClass]="(hasRightsOwner$ | async) ? 'black-text' : 'accent-text'">Rightsowner:*</p>
    }
    @if (rightsOwnerList$ | async; as rightsOwnerList) {
      @if(rightsOwnerList.length > 0) {
        
        @if (physicalEntity$ | async) {
          <p>Rightsowner:*</p> 
        }   
        @for (agent of rightsOwnerList; track $index) {
        <app-agent-card 
          [agent]="agent" 
          [entityId]="entityId"
          (remove)="removeAgentRole((isPerson(agent) ? 'persons' : 'institutions'), 'RIGHTS_OWNER', agent._id.toString())"
          ></app-agent-card>
        }
      }
    }

    @if (digitalEntity$ | async; as digitalEntity) {
      <p [ngClass]="(hasContactPerson$ | async) ? 'black-text' : 'accent-text'">Contact person:*</p>
    }
    @if (contactPersonList$ | async; as contactPersonList) {
      @if (contactPersonList.length > 0) { 
        @if (physicalEntity$ | async) {
          <p>Contact person:*</p>
        }
        @for (agent of contactPersonList; track agent; let index = $index) {
          <app-agent-card 
            [agent]="agent" 
            [entityId]="entityId"
            (remove)="removeAgentRole((isPerson(agent) ? 'persons' : 'institutions'), 'CONTACT_PERSON', agent._id.toString())"
            ></app-agent-card>
        }
      }
    }

    @if (creatorList$ | async; as creatorList) {
    @if(creatorList.length > 0) {
        <p>Creator:</p>
        @for (agent of creatorList; track $index) {
          <app-agent-card 
            [agent]="agent" 
            [entityId]="entityId"
            (remove)="removeAgentRole((isPerson(agent) ? 'persons' : 'institutions'), 'CREATOR', agent._id.toString())"
            ></app-agent-card>
        }
      }
    }

    @if (editorList$ | async; as editorList) {
    @if(editorList.length > 0) {
        <p>Editor:</p>
        @for (agent of editorList; track $index) {
          <app-agent-card 
            [agent]="agent" 
            [entityId]="entityId"
            (remove)="removeAgentRole((isPerson(agent) ? 'persons' : 'institutions'), 'EDITOR', agent._id.toString())"
            ></app-agent-card>
        }
      }
    }

    @if (dataCreatorList$ | async; as dataCreatorList) {
    @if(dataCreatorList.length > 0) {
        <p>Data Creator:</p>
        @for (agent of dataCreatorList; track $index) {
          <app-agent-card 
            [agent]="agent" 
            [entityId]="entityId"
            (remove)="removeAgentRole((isPerson(agent) ? 'persons' : 'institutions'), 'DATA_CREATOR', agent._id.toString())"
            ></app-agent-card>
        }
      }
    }
}
