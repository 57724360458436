@if (person$ | async; as person) {

  <div *ngIf="(roles$ | async) as roles">
    <p class="capitalize">
      <ng-container *ngFor="let role of roles; let last = last">
        {{ role }}<span *ngIf="!last">, </span><span *ngIf="last">: </span>
      </ng-container>
    </p>
    <p class="blue-underline-text"> {{ person.prename }} {{ person.name }} </p>
    @if (contact$ | async; as contact) {
      @if (contact.mail) {
        <p>{{ contact.mail }}</p>
      }
      <!-- @if (contact.note) {
        <p>{{ contact.note }}</p>
      } -->
      @if (contact.phonenumber) {
        <p>{{ contact.phonenumber }}</p>
      }
    }
  </div>

  @if (institutions$ | async; as institutions) {
    <div class="block">
      @for (institution of institutions; track institution) {
        <app-detail-institution
          [institution]="institution"
        ></app-detail-institution>
      }
    </div>
  }
}
