@if (entity$ | async; as entity) {

  <mat-sidenav-container>
    <mat-sidenav opened mode="side">
      <mat-action-list>
        <button
          mat-list-item 
          (click)="selectTab('General')"
          [ngClass]=" {'active-tab': indexString == 'General' }">
            <mat-icon
              matTooltip="{{ 'Section contains required fields' | translate }}"
              [color]="(generalInformationValid$ | async) ? 'primary' : 'accent'"
              class="required-info"
              >
              label_important
            </mat-icon>
            {{ 'General information' | translate }}
        </button>
        <button 
          mat-list-item 
          (click)="selectTab('Licence')"
          [ngClass]=" {'active-tab': indexString == 'Licence' }">
          <mat-icon
            matTooltip="{{ 'Section contains required fields' | translate }}"
            [color]="(licenceValid$ | async) ? 'primary' : 'accent'"
            class="required-info"
            >
            label_important
          </mat-icon>
          {{ 'Licence' | translate }}
        </button>
        
        <button 
          mat-list-item 
          (click)="selectTab('Related')"
          [ngClass]=" {'active-tab': indexString == 'Related' }">
          @if (digitalEntity$ | async; as digitalEntity) {
            <mat-icon
            matTooltip="{{ 'Section contains required fields' | translate }}"
              [color]="
                (personsValid$ | async) && (institutionsValid$ | async) && (hasRightsOwner$ | async) && (hasContactPerson$ | async)
                  ? 'primary'
                  : 'accent'
              "
            class="required-info"
            >
            label_important
          </mat-icon>
          } @else {
            <mat-icon
              matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
              [color]="(institutionsValid$ | async) ? 'primary' : 'accent'"
              class="required-info"
              >
              label
            </mat-icon>
          }
          <span>{{ 'Related persons and institutions' | translate }}</span>
        </button>

        <p class="optional-text">{{'Optional metadata:' | translate }}</p>
        <button 
          mat-list-item 
          (click)="selectTab('Dimensions')"
          [ngClass]=" {'active-tab': indexString == 'Dimensions' }">
          @if (digitalEntity$ | async; as digitalEntity) {
          <mat-icon
            matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
            [color]="(dimensionsValid$ | async) ? 'primary' : 'accent'"
            class="required-info"
            >
            label
          </mat-icon>
          <span>{{ 'Dimensions' | translate }}</span>
        }
        </button>
        <button 
          mat-list-item 
          (click)="selectTab('Creation')"
          [ngClass]=" {'active-tab': indexString == 'Creation' }">
          @if (digitalEntity$ | async; as digitalEntity) {
            <mat-icon
              matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
              [color]="(creationValid$ | async) ? 'primary' : 'accent'"
              class="required-info"
              >
              label
            </mat-icon>
            <span>{{ 'Creation' | translate }}</span>
          }
        </button>

        <button 
          mat-list-item 
          (click)="selectTab('Ids')"
          [ngClass]=" {'active-tab': indexString == 'Ids' }">
          <mat-icon
            matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
            [color]="(externalIdValid$ | async) ? 'primary' : 'accent'"
            class="required-info"
            >
            label
          </mat-icon>
          <span>{{ 'External Identifiers' | translate }}</span>
        </button>

        <button 
          mat-list-item 
          (click)="selectTab('Links')"
          [ngClass]=" {'active-tab': indexString == 'Links' }">
          <mat-icon
            matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
            [color]="(externalLinkValid$ | async) ? 'primary' : 'accent'"
            class="required-info"
            >
            label
          </mat-icon>
          <span>{{ 'External Links' | translate }}</span>
        </button>
        <button 
          mat-list-item 
          (click)="selectTab('References')"
          [ngClass]=" {'active-tab': indexString == 'References' }">
          <mat-icon
          matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
          [color]="(biblioRefsValid$ | async) ? 'primary' : 'accent'"
          class="required-info"
          >
          label
          </mat-icon>
          <span>{{ 'Bibliographic references' | translate }}</span>
        </button>
        <button 
          mat-list-item 
          (click)="selectTab('Files')"
          [ngClass]=" {'active-tab': indexString == 'Files' }">
          <mat-icon
            matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
            [color]="(metadataFilesValid$ | async) ? 'primary' : 'accent'"
            class="required-info"
            >
            label
          </mat-icon>
          <span>{{ 'Metadata files' | translate }}</span>
        </button>
        <button 
          mat-list-item 
          (click)="selectTab('Physical')"
          [ngClass]=" {'active-tab': indexString == 'Physical' }">
          @if (digitalEntity$ | async; as digitalEntity) {
            <mat-icon
            matTooltip="{{ 'Section is optional, but any added elements need to be filled' | translate }}"
            [color]="phyObjsValid() ? 'primary' : 'accent'"
            >
            label
            </mat-icon>
            <span>{{ 'Physical object' | translate }}</span>
           }
        </button> 
      </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-tab-group 
        [(selectedIndex)]="selectedTabIndex" 
        animationDuration="0ms" 
        dynamicHeight>
        <!-- General Information -->
         <mat-tab>
          @if (digitalEntity$ | async; as digitalEntity) {
          <app-general 
            [entity]="entity" 
            [digitalEntity]="digitalEntity"
            (remove)="removeValueFromProperty(digitalEntity, $event)" ></app-general>
          }
        </mat-tab>
        <!-- /General Information -->

        <!-- Licence -->
        <mat-tab>
          @if (digitalEntity$ | async; as digitalEntity) {
            <p>{{ "Select a license that fits the way other users are allowed to use it." | translate }}</p>
          <div class="form-fields">
            <mat-radio-group class="licence-selection" [(ngModel)]="digitalEntity.licence">
              @for (licence of availableLicences; track licence) {
                <mat-radio-button [value]="licence.title">
                  <img [src]="licence.src" [alt]="licence.title" />
                  <a
                    [href]="licence.link"
                    target="_blank"
                    referrerpolicy="no-referrer"
                    rel="noreferrer"
                    [title]="licence.link"
                    >{{ licence.description }}</a
                    >
                  </mat-radio-button>
                }
              </mat-radio-group>
          </div>
        }
        </mat-tab>
        <!-- /Licence -->

        <!-- Related persons and institutions -->
        <mat-tab>
          @if (_id$ | async; as entityId) {
            <div>
              <app-agents [entity]="entity" [entityId]="entityId"></app-agents>  
            </div>
          }
        </mat-tab>
          <!-- /Related persons and institutions-->

        <!-- Dimensions -->
        <mat-tab>
        @if (digitalEntity$ | async; as digitalEntity) {
          <app-dimension [entity]="digitalEntity"></app-dimension>
        }
        </mat-tab>
        <!-- /Dimensions -->

        <!-- Creation -->
        <mat-tab>
        @if (digitalEntity$ | async; as digitalEntity) {
          <app-creation [entity]="digitalEntity"></app-creation>
          <!-- <mat-divider></mat-divider> -->
          <!-- <app-creation-card [entity]="digitalEntity" (remove)="removeValueFromProperty(digitalEntity, $event)"></app-creation-card> -->
        }
        </mat-tab>
        <!-- /Creation -->

        <!-- External Identifiers -->
        <mat-tab>
          @if (digitalEntity$ | async; as digitalEntity) {
            <app-external-ids [entity]="digitalEntity"></app-external-ids>
          }
        </mat-tab>
        <!-- /External Identifiers -->

        <!-- External Links -->
        <mat-tab>
        <app-links [entity]="entity"></app-links>
        </mat-tab>
        <!-- /External Links -->

        <!-- Bibliographic References -->
        <mat-tab>
          <app-biblio-ref [entity]="entity"></app-biblio-ref>
        </mat-tab>
        <!-- /Bibliographic References -->

        <!-- Other -->
        <!-- <mat-tab>
          <div>
            @for (other of entity.other; track other; let index = $index) {
              <div
                class="card"
                [class.invalid]="!DescriptionValueTuple.checkIsValid(other)"
                >
                <mat-form-field>
                  <mat-label>{{ 'Description' | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    name="description"
                    placeholder="{{ 'Enter a description' | translate }}"
                    [(ngModel)]="other.description"
                    />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'Value' | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    name="value"
                    placeholder="{{ 'Enter a value' | translate }}"
                    [(ngModel)]="other.value"
                    />
                </mat-form-field>
                <div class="actions">
                  <button mat-icon-button color="warn" (click)="removeProperty(entity, 'other', index)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            }
          </div>
        </mat-tab> -->
        <!-- /Other -->

        <!-- Metadata Files -->
        <mat-tab>
          @if (digitalEntity$ | async; as digitalEntity) {
          <app-metadata-files [entity]="digitalEntity"></app-metadata-files>
          }
        </mat-tab>
        <!-- /Metadata Files -->

        <!-- Physical Objects -->
         <mat-tab>
          @if (digitalEntity$ | async; as digitalEntity) {
            <app-phys-obj [entity]="digitalEntity"></app-phys-obj>
          }
         </mat-tab>

        <!-- /Physical Objects -->

      </mat-tab-group>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
