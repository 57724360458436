<form [formGroup]="form" (submit)="trySubmit()">
  <div class="forgotPasswordDialog">
    <div class="titleAndClose">
      <h2>{{ 'Forgot your password?' | translate }}</h2>
      <button
        mat-icon-button
        class="close-dialog-button"
        (click)="dialogRef.close(false)"
        tabindex="-1"
        type="button"
        >
        <mat-icon>close</mat-icon>
        </button>
    </div>
    
    <p>{{ 'You can request your password using your username and will receive a mail!' | translate }}</p>
    <mat-form-field class="fullwidth">
      <mat-label>{{ 'Enter your username' | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="username"
        name="username"
        formControlName="username"
        required
        />
    </mat-form-field>

    <button mat-flat-button type="submit" color="primary" [disabled]="!form.valid">
    {{ 'Submit password request' | translate }}
    </button>
  </div>
</form>

@if (serverErrorMsg.length > 0) {
  <p class="errors">
    {{ serverErrorMsg }}
  </p>
}
