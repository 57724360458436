<form [formGroup]="form" (submit)="trySubmit()">
  <div class="forgotUsernameDialog">
    <div class="titleAndClose">
      <h2>{{ 'Forgot your username?' | translate }}</h2>
      <button
        mat-icon-button
        class="close-dialog-button"
        (click)="dialogRef.close(false)"
        tabindex="-1"
        type="button"
        >
        <mat-icon>close</mat-icon>
        </button>
      </div>
    <p>{{ 'You can request your username using your e-mail address!' | translate }}</p>
    <mat-form-field class="fullwidth">
      <mat-label>{{ 'Enter your e-mail address' | translate }}</mat-label>
      <input
       matInput
        type="text"
        autocomplete="email"
        name="mail"
        formControlName="mail"
        required
        />
    </mat-form-field>

    <button mat-flat-button type="submit" color="primary" [disabled]="!form.valid">
      {{ 'Send e-mail' | translate }}
    </button>
  </div>
</form>

@if (serverErrorMsg.length > 0) {
  <p class="errors">
    {{ serverErrorMsg }}
  </p>
}
